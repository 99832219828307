import React from 'react';
import { SEO } from '../components/SEO';
import { AboutHero } from '../components/about/AboutHero';
import { AboutStats } from '../components/about/AboutStats';
import { AboutUSPs } from '../components/about/AboutUSPs';
import { AboutInfographic } from '../components/about/AboutInfographic';
import { AboutStory } from '../components/about/AboutStory';
import { AboutMission } from '../components/about/AboutMission';
import { AboutFeatures } from '../components/about/AboutFeatures';

export function AboutPage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <SEO 
        title="About MerchantFinder | Your Trade Merchant Directory"
        description="MerchantFinder connects you with quality building and trade merchants across the UK. Discover how we help you find trusted local suppliers for all your construction needs."
      />
      
      <AboutHero />
      <AboutStats />
      <AboutStory />
      <AboutInfographic />
      <AboutMission />
      <AboutUSPs />
      <AboutFeatures />
    </div>
  );
}