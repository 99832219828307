import React from 'react';
import { Shield, Users, Star, Clock, MapPin, Building2, Truck, Zap } from 'lucide-react';

export function AboutFeatures() {
  return (
    <div className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-gray-50 rounded-xl p-6">
            <Shield className="w-8 h-8 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Verified Merchants</h3>
            <p className="text-gray-600">
              We carefully vet each merchant to ensure they meet our quality standards and maintain 
              excellent stock levels and customer service.
            </p>
          </div>
          
          <div className="bg-gray-50 rounded-xl p-6">
            <Building2 className="w-8 h-8 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Trade Expertise</h3>
            <p className="text-gray-600">
              Our merchants specialize in building, electrical, plumbing, roofing, and timber supplies, 
              offering expert advice and quality products.
            </p>
          </div>
          
          <div className="bg-gray-50 rounded-xl p-6">
            <Star className="w-8 h-8 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Customer Reviews</h3>
            <p className="text-gray-600">
              Real reviews from trade professionals and customers help you make informed decisions 
              about your supplier choice.
            </p>
          </div>
          
          <div className="bg-gray-50 rounded-xl p-6">
            <Truck className="w-8 h-8 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Delivery Services</h3>
            <p className="text-gray-600">
              Find merchants offering reliable delivery services for your building materials 
              and trade supplies.
            </p>
          </div>
          
          <div className="bg-gray-50 rounded-xl p-6">
            <MapPin className="w-8 h-8 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Local Focus</h3>
            <p className="text-gray-600">
              Easily locate merchants in your area for quick access to building supplies and 
              trade materials.
            </p>
          </div>
          
          <div className="bg-gray-50 rounded-xl p-6">
            <Zap className="w-8 h-8 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Trade Accounts</h3>
            <p className="text-gray-600">
              Find merchants offering trade accounts with competitive pricing and credit terms 
              for regular customers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}