import type { City, Merchant } from './types';

export const cities: City[] = [
  { name: 'London', slug: 'london' },
  { name: 'Birmingham', slug: 'birmingham' },
  { name: 'Manchester', slug: 'manchester' },
  { name: 'Liverpool', slug: 'liverpool' },
  { name: 'Leeds', slug: 'leeds' },
  { name: 'Sheffield', slug: 'sheffield' },
  { name: 'Teesside', slug: 'teesside' },
  { name: 'Bristol', slug: 'bristol' },
  { name: 'Bournemouth and Poole', slug: 'bournemouth-and-poole' },
  { name: 'Stoke-on-Trent', slug: 'stoke-on-trent' },
  { name: 'Leicester', slug: 'leicester' },
  { name: 'Wirral', slug: 'wirral' },
  { name: 'Coventry', slug: 'coventry' },
  { name: 'Nottingham', slug: 'nottingham' },
  { name: 'Bradford', slug: 'bradford' },
  { name: 'Newcastle', slug: 'newcastle' },
  { name: 'Bolton', slug: 'bolton' },
  { name: 'Brighton and Hove', slug: 'brighton-and-hove' },
  { name: 'Plymouth', slug: 'plymouth' },
  { name: 'Hull', slug: 'hull' },
  { name: 'Preston', slug: 'preston' },
  { name: 'Derby', slug: 'derby' },
  { name: 'Southampton', slug: 'southampton' },
  { name: 'Portsmouth', slug: 'portsmouth' },
  { name: 'Reading', slug: 'reading' },
  { name: 'Norwich', slug: 'norwich' },
  { name: 'Milton Keynes', slug: 'milton-keynes' },
  { name: 'Peterborough', slug: 'peterborough' }
].sort((a, b) => a.name.localeCompare(b.name));

export const merchants: Merchant[] = [
  { name: 'Builders Merchants', slug: 'builders-merchants' },
  { name: 'Electrical Merchants', slug: 'electrical-merchants' },
  { name: 'Plumbers Merchants', slug: 'plumbers-merchants' },
  { name: 'Roofing Merchants', slug: 'roofing-merchants' },
  { name: 'Timber Merchants', slug: 'timber-merchants' }
].sort((a, b) => a.name.localeCompare(b.name));