import React from 'react';
import { Users, Store, Star, MapPin } from 'lucide-react';

interface StatItemProps {
  icon: React.ElementType;
  value: string;
  label: string;
}

function StatItem({ icon: Icon, value, label }: StatItemProps) {
  return (
    <div className="text-center">
      <Icon className="w-8 h-8 text-blue-500 mx-auto mb-3" />
      <div className="text-3xl font-bold text-gray-900 mb-1">{value}</div>
      <div className="text-sm text-gray-600">{label}</div>
    </div>
  );
}

export function AboutStats() {
  return (
    <div className="bg-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
          MerchantFinder in Numbers
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          <StatItem
            icon={Store}
            value="2,500+"
            label="Verified Merchants"
          />
          <StatItem
            icon={Users}
            value="50,000+"
            label="Trade Professionals"
          />
          <StatItem
            icon={Star}
            value="98%"
            label="Customer Satisfaction"
          />
          <StatItem
            icon={MapPin}
            value="150+"
            label="UK Locations"
          />
        </div>
      </div>
    </div>
  );
}