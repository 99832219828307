import React from 'react';

export function AboutStory() {
  return (
    <div className="py-16 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">Our Story</h2>
          <div className="prose prose-lg mx-auto text-gray-600">
            <p>
              MerchantFinder was founded with a clear mission: to make finding quality building 
              and trade merchants simple, transparent, and reliable. We understand that finding 
              the right suppliers at the right price can be challenging, which is why we've created 
              a platform that connects you with trusted local merchants.
            </p>
            <p>
              Our platform helps both trade professionals and DIY enthusiasts find the building 
              materials and supplies they need. We work with verified merchants who maintain high 
              standards of service and stock quality, ensuring you get the best products for your projects.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}