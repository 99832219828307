import React from 'react';
import { FooterCompanyInfo } from './footer/FooterCompanyInfo';
import { FooterCategories } from './footer/FooterCategories';
import { FooterLocations } from './footer/FooterLocations';
import { FooterLinks } from './footer/FooterLinks';

export function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-100">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          <FooterCompanyInfo />
          <FooterCategories />
          <FooterLocations />
          <FooterLinks />
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center pt-8 border-t border-gray-800">
          <p className="text-sm text-gray-400 mb-4 md:mb-0">
            © 2025 MerchantFinder. All rights reserved.
          </p>
          <div className="flex items-center gap-4">
            <a 
              href="https://screwhero.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-gray-400 hover:text-blue-400 transition-colors"
            >
              List Your Business
            </a>
            <span className="text-gray-600">•</span>
            <a 
              href="https://screwhero.com/blog"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-gray-400 hover:text-blue-400 transition-colors"
            >
              Trade Blog
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}