import React from 'react';
import { CheckCircle2 } from 'lucide-react';

const usps = [
  {
    title: "Comprehensive Coverage",
    description: "Access to over 2,500 verified merchants across all major UK cities"
  },
  {
    title: "Trade-Focused Platform",
    description: "Specialized in building, electrical, plumbing, roofing, and timber merchants"
  },
  {
    title: "Real-Time Stock Updates",
    description: "Live inventory information from connected merchants"
  },
  {
    title: "Trade Account Support",
    description: "Easy application process for trade accounts with multiple merchants"
  },
  {
    title: "Verified Reviews",
    description: "Authentic feedback from trade professionals and customers"
  },
  {
    title: "Price Comparison",
    description: "Compare prices and delivery options across multiple merchants"
  }
];

export function AboutUSPs() {
  return (
    <div className="bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
          Why Choose MerchantFinder?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {usps.map((usp, index) => (
            <div key={index} className="flex items-start gap-4">
              <CheckCircle2 className="w-6 h-6 text-blue-500 flex-shrink-0 mt-1" />
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  {usp.title}
                </h3>
                <p className="text-gray-600">{usp.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}