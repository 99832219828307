import { META_CONTENT } from '../constants/meta';
import type { City, Merchant } from '../types';
import type { GeneratedMeta } from './types';
import { CanonicalUrlGenerator } from '../utils/canonical';

export function generateSearchMeta(merchant: Merchant, city: City, page?: number): GeneratedMeta {
  const pageTitle = page && page > 1 ? ` - Page ${page}` : '';
  const merchantType = merchant.slug.toUpperCase().replace(/-/g, '_');
  
  const title = META_CONTENT.TITLES[merchantType]?.(city.name) || 
    `${merchant.name} in ${city.name}${pageTitle} | Local Equipment Hire`;
  
  const description = META_CONTENT.DESCRIPTIONS[merchantType]?.(city.name) ||
    `Find quality ${merchant.name.toLowerCase()} in ${city.name}. Compare local providers, read reviews, and get competitive prices.`;

  return {
    title,
    description,
    canonicalUrl: CanonicalUrlGenerator.getSearchCanonical(merchant, city, page),
    keywords: `${merchant.name.toLowerCase()}, equipment hire, ${city.name}, local hire, hire services`
  };
}

export function generateHomeMeta(): GeneratedMeta {
  return {
    title: META_CONTENT.TITLES.HOME,
    description: 'Find quality equipment hire near you. Compare local providers, read reviews, and hire the right equipment for your project.',
    canonicalUrl: CanonicalUrlGenerator.getHomeCanonical(),
    keywords: 'equipment hire, tool hire, plant hire, skip hire, scaffold hire, local hire services'
  };
}

export function generateStaticPageMeta(page: 'ABOUT' | 'CONTACT' | 'CATEGORIES' | 'PRIVACY' | 'TERMS'): GeneratedMeta {
  const descriptions = {
    ABOUT: 'Learn about HireFy and our mission to help you find the right equipment hire solutions. Discover how we connect you with trusted local hire providers.',
    CONTACT: 'Get in touch with HireFy. We\'re here to help you find the perfect equipment hire solutions for your projects.',
    CATEGORIES: 'Browse our equipment hire categories. From tools to machinery, find the right equipment hire services for your needs.',
    PRIVACY: 'Read our privacy policy to understand how HireFy protects your data while helping you find equipment hire services.',
    TERMS: 'Review our terms of use for using HireFy\'s equipment hire platform.'
  };

  const path = page.toLowerCase();
  
  return {
    title: META_CONTENT.TITLES[page],
    description: descriptions[page],
    canonicalUrl: CanonicalUrlGenerator.getStaticPageCanonical(path),
    keywords: 'equipment hire, tool hire, plant hire, local hire services'
  };
}