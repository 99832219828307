import React from 'react';
import { MetaTags } from '../components/seo/MetaTags';
import { SearchForm } from '../components/SearchForm';
import { FeaturedCategories } from '../components/FeaturedCategories';
import { PopularMerchants } from '../components/PopularMerchants';
import { generateHomeMeta } from '../lib/seo/generators';
import { MerchantIcon } from '../components/icons/MerchantIcon';

export function HomePage() {
  const seo = generateHomeMeta();

  return (
    <div className="min-h-screen bg-gray-50">
      <MetaTags {...seo} />

      <div className="bg-gradient-to-r from-gray-900 to-gray-800 py-20">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <MerchantIcon className="w-20 h-20 text-blue-500 mx-auto mb-6" />
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
              Find Building & Trade Merchants Near You
            </h1>
            <p className="text-xl text-gray-300">
              Discover trusted local merchants for building materials, electrical supplies, plumbing, roofing, and timber
            </p>
          </div>
          <SearchForm />
        </div>
      </div>

      <FeaturedCategories />

      <div className="bg-gray-50">
        <PopularMerchants
          title="Popular Builders Merchants in London"
          merchantType="Builders Merchants"
          merchantSlug="builders-merchants"
        />
        
        <div className="border-t border-gray-200">
          <PopularMerchants
            title="Electrical Merchants in Manchester"
            merchantType="Electrical Merchants"
            merchantSlug="electrical-merchants"
          />
        </div>
      </div>
    </div>
  );
}