import React from 'react';
import { Search, Store, ClipboardCheck, Truck } from 'lucide-react';

const steps = [
  {
    icon: Search,
    title: "Search",
    description: "Find merchants by location and category"
  },
  {
    icon: Store,
    title: "Compare",
    description: "Review prices, stock, and delivery options"
  },
  {
    icon: ClipboardCheck,
    title: "Choose",
    description: "Select the best merchant for your needs"
  },
  {
    icon: Truck,
    title: "Deliver",
    description: "Get materials delivered or collect in-store"
  }
];

export function AboutInfographic() {
  return (
    <div className="bg-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
          How It Works
        </h2>
        <div className="relative">
          {/* Connection line */}
          <div className="hidden md:block absolute top-1/2 left-0 right-0 h-0.5 bg-blue-100 -translate-y-1/2" />
          
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {steps.map((step, index) => (
              <div key={index} className="relative">
                <div className="bg-white rounded-full w-16 h-16 mx-auto mb-4 flex items-center justify-center border-2 border-blue-500 relative z-10">
                  <step.icon className="w-8 h-8 text-blue-500" />
                </div>
                <h3 className="text-lg font-semibold text-center text-gray-900 mb-2">
                  {step.title}
                </h3>
                <p className="text-center text-gray-600">
                  {step.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}