import React from 'react';

export function MerchantIcon({ className = "w-16 h-16" }) {
  return (
    <svg 
      viewBox="0 0 64 64" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="64" height="64" rx="16" className="fill-blue-600" />
      <path 
        d="M16 24L20 16H44L48 24M16 24V48C16 49.0609 16.4214 50.0783 17.1716 50.8284C17.9217 51.5786 18.9391 52 20 52H44C45.0609 52 46.0783 51.5786 46.8284 50.8284C47.5786 50.0783 48 49.0609 48 48V24M16 24H48M32 32V40M24 32V40M40 32V40" 
        stroke="currentColor" 
        strokeWidth="4" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        className="stroke-white"
      />
    </svg>
  );
}