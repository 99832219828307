import React from 'react';
import { Award, Users, Coins, TrendingUp, ArrowRight } from 'lucide-react';

export function SponsoredAd() {
  return (
    <div className="relative overflow-hidden bg-gradient-to-br from-indigo-50 via-white to-blue-50 rounded-2xl shadow-xl border border-indigo-100 p-4 sm:p-6 md:p-8 mb-8 transform transition-all duration-300 hover:scale-[1.01]">
      {/* Decorative Elements - Responsive sizes */}
      <div className="absolute top-0 right-0 w-48 sm:w-72 md:w-96 h-48 sm:h-72 md:h-96 bg-gradient-to-br from-indigo-500/5 via-blue-500/5 to-emerald-500/5 rounded-full blur-3xl" />
      <div className="absolute bottom-0 left-0 w-48 sm:w-72 md:w-96 h-48 sm:h-72 md:h-96 bg-gradient-to-tr from-blue-500/5 via-purple-500/5 to-pink-500/5 rounded-full blur-3xl" />

      {/* Sponsored Badge - Responsive positioning */}
      <div className="absolute top-2 sm:top-4 right-2 sm:right-4 flex items-center gap-1 sm:gap-2 bg-gradient-to-r from-indigo-500 to-blue-500 text-white px-2 sm:px-4 py-1 sm:py-1.5 rounded-full shadow-lg">
        <Award className="w-3 h-3 sm:w-4 sm:h-4" />
        <span className="text-xs sm:text-sm font-medium">Featured Partner</span>
      </div>

      <div className="relative flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4 sm:gap-6 lg:gap-8 mt-8 sm:mt-6">
        {/* Left Content */}
        <div className="flex-1">
          {/* Trust Badge */}
          <div className="flex items-center gap-1 sm:gap-2 mb-3 sm:mb-4">
            <Users className="w-3 h-3 sm:w-4 sm:h-4 text-emerald-600" />
            <span className="text-xs sm:text-sm font-medium text-emerald-700">
              Join 1000+ UK Trade Professionals
            </span>
          </div>

          {/* Title - Responsive text size */}
          <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-900 mb-4 sm:mb-6">
            Grow Your Trade Business with ScrewHero
          </h2>

          {/* Features - Responsive grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 mb-4 sm:mb-6">
            <div className="flex items-start gap-2 sm:gap-3">
              <div className="flex-shrink-0 w-8 sm:w-10 h-8 sm:h-10 bg-gradient-to-br from-indigo-100 to-blue-50 rounded-lg sm:rounded-xl flex items-center justify-center">
                <TrendingUp className="w-4 sm:w-5 h-4 sm:h-5 text-indigo-600" />
              </div>
              <div>
                <h3 className="text-sm sm:text-base font-semibold text-gray-900">Grow Your Business</h3>
                <p className="text-xs sm:text-sm text-gray-600">
                  Reach more local customers actively searching for your services
                </p>
              </div>
            </div>
            <div className="flex items-start gap-2 sm:gap-3">
              <div className="flex-shrink-0 w-8 sm:w-10 h-8 sm:h-10 bg-gradient-to-br from-emerald-100 to-blue-50 rounded-lg sm:rounded-xl flex items-center justify-center">
                <Coins className="w-4 sm:w-5 h-4 sm:h-5 text-emerald-600" />
              </div>
              <div>
                <h3 className="text-sm sm:text-base font-semibold text-gray-900">Affordable Plans</h3>
                <p className="text-xs sm:text-sm text-gray-600">
                  Simple, time-based plans that fit your budget goals
                </p>
              </div>
            </div>
          </div>

          {/* Special Offer - Responsive text and padding */}
          <div className="inline-flex items-center gap-1 sm:gap-2 bg-gradient-to-r from-amber-50 to-yellow-50 border border-amber-200/50 text-amber-700 px-3 sm:px-4 py-1.5 sm:py-2 rounded-full text-xs sm:text-sm font-medium shadow-sm">
            <span className="text-base sm:text-lg">🎉</span>
            Special Launch Offer: 80% off your first 3 months!
          </div>
        </div>

        {/* Right Content - CTA */}
        <div className="flex flex-col items-start lg:items-center mt-4 sm:mt-6 lg:mt-0">
          <div className="relative group w-full sm:w-auto">
            <div className="absolute -inset-1 bg-gradient-to-r from-indigo-600 to-blue-600 rounded-lg blur opacity-25 group-hover:opacity-40 transition duration-200" />
            <a
              href="https://screwhero.com"
              target="_blank"
              rel="noopener noreferrer"
              className="relative w-full sm:w-auto flex items-center justify-center gap-2 bg-gradient-to-r from-indigo-600 to-blue-600 text-white px-4 sm:px-6 py-2.5 sm:py-3 rounded-lg font-semibold transition-all duration-200 hover:shadow-lg hover:shadow-blue-500/25"
            >
              Start Growing Today
              <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
            </a>
          </div>
          <p className="text-xs sm:text-sm text-gray-500 mt-2 sm:mt-3 text-center w-full">No commission fees • Cancel anytime</p>
        </div>
      </div>
    </div>
  );
}