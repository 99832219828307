import { Construction, Zap, Wrench, Home, Trees } from 'lucide-react';
import type { CategoryConfig } from '../types';

export const featuredCategories: CategoryConfig[] = [
  {
    title: "Builders Merchants",
    description: "Quality building materials and supplies for construction projects",
    icon: Construction,
    link: "/builders-merchants/london",
    image: "https://images.unsplash.com/photo-1581094794329-c8112a89af12"
  },
  {
    title: "Electrical Merchants",
    description: "Professional electrical supplies and components",
    icon: Zap,
    link: "/electrical-merchants/manchester",
    image: "https://images.unsplash.com/photo-1558424087-896279962d7c"
  },
  {
    title: "Plumbers Merchants",
    description: "Comprehensive range of plumbing and heating supplies",
    icon: Wrench,
    link: "/plumbers-merchants/birmingham",
    image: "https://images.unsplash.com/photo-1607472586893-edb57bdc0e39"
  },
  {
    title: "Roofing Merchants",
    description: "Professional roofing materials and accessories",
    icon: Home,
    link: "/roofing-merchants/leeds",
    image: "https://images.unsplash.com/photo-1632759145351-1d592919f522"
  },
  {
    title: "Timber Merchants",
    description: "Quality timber, sheet materials and wood products",
    icon: Trees,
    link: "/timber-merchants/london",
    image: "https://images.unsplash.com/photo-1520627977056-c307aeb9a625"
  }
];