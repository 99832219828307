import React from 'react';
import { Link } from 'react-router-dom';

export function FooterLinks() {
  return (
    <div>
      <h3 className="font-semibold text-white mb-4">Quick Links</h3>
      <ul className="space-y-2">
        <li>
          <Link to="/about" className="text-gray-300 hover:text-blue-400 transition-colors">
            About Us
          </Link>
        </li>
        <li>
          <Link to="/contact" className="text-gray-300 hover:text-blue-400 transition-colors">
            Contact
          </Link>
        </li>
        <li>
          <Link to="/categories" className="text-gray-300 hover:text-blue-400 transition-colors">
            All Categories
          </Link>
        </li>
        <li>
          <Link to="/privacy-policy" className="text-gray-300 hover:text-blue-400 transition-colors">
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link to="/terms-of-use" className="text-gray-300 hover:text-blue-400 transition-colors">
            Terms of Use
          </Link>
        </li>
      </ul>
    </div>
  );
}