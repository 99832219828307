import React from 'react';
import { Mail, MapPin } from 'lucide-react';
import { MerchantIcon } from '../icons/MerchantIcon';

export function FooterCompanyInfo() {
  return (
    <div>
      <div className="flex items-center gap-2 mb-4">
        <MerchantIcon className="w-8 h-8" />
        <span className="font-bold text-white text-lg">MerchantFinder</span>
      </div>
      <p className="text-sm mb-4 text-gray-300">
        Your trusted platform for finding quality building and trade merchants across the UK. 
        Compare local suppliers for all your construction and trade needs.
      </p>
      <div className="space-y-2">
        <div className="flex items-center gap-2 text-gray-300 hover:text-blue-400 transition-colors">
          <Mail className="w-4 h-4" />
          <a href="mailto:info@merchantfinder.co.uk">info@merchantfinder.co.uk</a>
        </div>
        <div className="flex items-center gap-2 text-gray-300">
          <MapPin className="w-4 h-4" />
          <span>London, UK</span>
        </div>
      </div>
    </div>
  );
}