export const META_CONTENT = {
  SITE_NAME: 'MerchantFinder',
  BASE_URL: 'https://www.merchantfinder.co.uk',
  DEFAULT_OG_IMAGE: 'https://www.merchantfinder.co.uk/og-image.jpg',
  TWITTER_HANDLE: '@merchantfinder',

  DESCRIPTIONS: {
    HOME: 'Find quality building and trade merchants across the UK. Compare local suppliers for building materials, electrical supplies, plumbing, roofing, and timber.',
    
    ABOUT: 'Learn about MerchantFinder and how we help you find trusted building and trade merchants. Discover our mission to connect you with quality local suppliers.',
    
    CONTACT: 'Get in touch with MerchantFinder. We\'re here to help you find the right building and trade merchants for your needs.',
    
    CATEGORIES: 'Browse our comprehensive directory of building and trade merchant categories. Find suppliers for building materials, electrical supplies, plumbing, and more.',
    
    PRIVACY: 'Read our privacy policy to understand how MerchantFinder protects your data while helping you find building and trade merchants.',
    
    TERMS: 'Review our terms of use for using MerchantFinder\'s building and trade merchant directory.',

    BUILDERS_MERCHANTS: (city: string) => 
      `Find trusted builders merchants in ${city}. Compare building materials, prices, and stock availability from local suppliers.`,
    
    ELECTRICAL_MERCHANTS: (city: string) => 
      `Discover reliable electrical merchants in ${city}. Compare electrical supplies, components, and trade prices from local suppliers.`,
    
    PLUMBERS_MERCHANTS: (city: string) => 
      `Find quality plumbers merchants in ${city}. Compare plumbing supplies, heating equipment, and bathroom products from local merchants.`,
    
    ROOFING_MERCHANTS: (city: string) => 
      `Locate professional roofing merchants in ${city}. Compare roofing materials, prices, and specialist supplies from local providers.`,
    
    TIMBER_MERCHANTS: (city: string) => 
      `Find trusted timber merchants in ${city}. Compare timber products, sheet materials, and wood supplies from local stockists.`
  },

  TITLES: {
    HOME: 'MerchantFinder | Find Quality Building & Trade Merchants Near You',
    
    ABOUT: 'About MerchantFinder | Your Trusted Trade Merchant Directory',
    
    CONTACT: 'Contact MerchantFinder | Get Help Finding Trade Merchants',
    
    CATEGORIES: 'Trade Merchant Categories | Building & Construction Suppliers',
    
    PRIVACY: 'Privacy Policy | MerchantFinder Trade Directory',
    
    TERMS: 'Terms of Use | MerchantFinder Trade Directory',

    BUILDERS_MERCHANTS: (city: string) => 
      `Builders Merchants in ${city} | Compare Building Materials & Supplies`,
    
    ELECTRICAL_MERCHANTS: (city: string) => 
      `Electrical Merchants in ${city} | Compare Trade Electrical Supplies`,
    
    PLUMBERS_MERCHANTS: (city: string) => 
      `Plumbers Merchants in ${city} | Compare Plumbing & Heating Supplies`,
    
    ROOFING_MERCHANTS: (city: string) => 
      `Roofing Merchants in ${city} | Compare Roofing Materials & Supplies`,
    
    TIMBER_MERCHANTS: (city: string) => 
      `Timber Merchants in ${city} | Compare Quality Timber & Sheet Materials`,

    MERCHANT_CATEGORY: (merchantName: string) => 
      `${merchantName} Near Me | Find Local Trade Suppliers`,

    NOT_FOUND: 'Page Not Found | MerchantFinder'
  }
};