import React from 'react';
import { Link } from 'react-router-dom';
import { merchants } from '../../lib/data';

export function FooterCategories() {
  return (
    <div>
      <h3 className="font-semibold text-white mb-4">Popular Categories</h3>
      <ul className="space-y-2">
        {merchants.map(merchant => (
          <li key={merchant.slug}>
            <Link 
              to={`/${merchant.slug}`}
              className="text-gray-300 hover:text-blue-400 transition-colors"
            >
              {merchant.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}