import React from 'react';
import { Link } from 'react-router-dom';
import { cities } from '../../lib/data';

export function FooterLocations() {
  const popularCities = ['London', 'Manchester', 'Birmingham', 'Leeds', 'Liverpool']
    .map(name => cities.find(city => city.name === name))
    .filter(Boolean);

  return (
    <div>
      <h3 className="font-semibold text-white mb-4">Popular Locations</h3>
      <div className="grid grid-cols-2 gap-2">
        {popularCities.map(city => city && (
          <Link 
            key={city.slug}
            to={`/builders-merchants/${city.slug}`}
            className="text-gray-300 hover:text-blue-400 transition-colors"
          >
            {city.name}
          </Link>
        ))}
      </div>
    </div>
  );
}