import React from 'react';
import { Target, Users, ShieldCheck } from 'lucide-react';

export function AboutMission() {
  return (
    <div className="bg-gray-900 py-16">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-white mb-4">Our Mission</h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Connecting trade professionals and DIY enthusiasts with quality building merchants across the UK
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center">
            <Target className="w-12 h-12 text-blue-400 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-white mb-3">Quality Focus</h3>
            <p className="text-gray-300">
              We partner with merchants who maintain high standards in product quality and customer service
            </p>
          </div>

          <div className="text-center">
            <Users className="w-12 h-12 text-blue-400 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-white mb-3">Community Driven</h3>
            <p className="text-gray-300">
              Building a network of trusted merchants and satisfied customers through verified reviews
            </p>
          </div>

          <div className="text-center">
            <ShieldCheck className="w-12 h-12 text-blue-400 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-white mb-3">Trusted Partners</h3>
            <p className="text-gray-300">
              Ensuring reliable access to building materials and trade supplies across the UK
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}