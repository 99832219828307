import React from 'react';
import { featuredCategories } from '../lib/config/categories';
import { CategoryCard } from './cards/CategoryCard';

export function FeaturedCategories() {
  return (
    <div className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Trade Merchant Categories
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Find quality building materials and trade supplies from trusted local merchants
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {featuredCategories.map((category, index) => (
            <CategoryCard key={index} category={category} />
          ))}
        </div>
      </div>
    </div>
  );
}