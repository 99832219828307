import React from 'react';
import { Link } from 'react-router-dom';
import { Star, MapPin } from 'lucide-react';
import { cities } from '../lib/data';

interface PopularMerchantsProps {
  title: string;
  merchantType: string;
  merchantSlug: string;
}

export function PopularMerchants({ title, merchantType, merchantSlug }: PopularMerchantsProps) {
  const majorCities = ['London', 'Manchester', 'Birmingham', 'Leeds', 'Liverpool']
    .map(cityName => cities.find(city => city.name === cityName))
    .filter(Boolean);

  return (
    <div className="py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">{title}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {majorCities.map(city => city && (
            <Link
              key={city.slug}
              to={`/${merchantSlug}/${city.slug}`}
              className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow p-6"
            >
              <div className="flex items-start justify-between">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">
                    {merchantType} in {city.name}
                  </h3>
                  <div className="flex items-center gap-1 mt-2 text-yellow-400">
                    <Star className="w-4 h-4 fill-current" />
                    <Star className="w-4 h-4 fill-current" />
                    <Star className="w-4 h-4 fill-current" />
                    <Star className="w-4 h-4 fill-current" />
                    <Star className="w-4 h-4 fill-current" />
                    <span className="text-sm text-gray-600 ml-1">Verified suppliers</span>
                  </div>
                </div>
                <MapPin className="w-5 h-5 text-blue-600" />
              </div>
              <p className="text-sm text-gray-600 mt-3">
                Find trusted {merchantType.toLowerCase()} in {city.name}. Compare prices, check stock availability, and get trade accounts.
              </p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}